<template>
    <div>
        <div class="left_z_index" :class="{side_pan_width: sideBarForProp}"
            :style="[sideBarForProp == false ? { width: 0 + 'rem' } : { width: 10 + 'rem' },]">
            <nav class="close-sidebar-16rem z_index_20 md:left-0 md:block md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden flex flex-wrap items-center justify-between z-50 w-100"
                v-if="sideBarForProp" id="mainNavBar">
                <div class="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto mx_height_side">
                    <button class="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                        type="button" v-on:click="toggleCollapseShow('bg-white m-2 py-3 px-6')">
                        <i class="fas fa-bars"></i>
                    </button>
                    <div class="md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded mt-0"
                        v-bind:class="collapseShow">
                        <div class="py-0 flex  mneminics_mt">
                            <h6 class="px-1 py-0 drop_btn font-thin text-center text-white sidebar_heading"
                                style="width: 100%; display: flex">
                                SWAB & SURGE SAVE
                            </h6>
                        </div>
                        <div v-if="showInsideData == false" class="left_lists_">
                            <ul v-if="true" class="left_mnemo h-full">
                                <li class="bg_right_">
                                    <button :class="['dropdownToggleBtn', 'text-left', 'w-full', 'rounded', 'td_btn', 'flex', darkDark !== 'white'  ? 'td_btn_dark' : 'drop_btn_light',]"
                                        v-on:click="toggle_height_handler_tih">
                                        TIH
                                        <span v-if="!isExpandWellSection"><i class="fas fa-angle-down"></i></span>
                                        <span v-else><i class="fas fa-angle-up"></i></span>
                                    </button>
                                    <ul v-if="isExpandWellSection" v-bind:style="{ height: isExpandWellSection ? '' : '0px', }" class="text-xs px-2 flex flex-col"
                                        :class="[darkDark !== 'white'  ? 'drop_backg' : '']" style="max-height: 30vh; overflow: auto;">
                                        <li v-for="(tihtooh,index) in TIH" :key="index" @click="TIH_Details(index)" :style="[tihtooh.status.toUpperCase() === 'A' ? {order : 1} : {order : 2}]">
                                            <label :for="'radio_sec' + index" :class="['text-left', 'w-full', 'rounded', 'td_btn', 'flex',]">
                                                <div class="flex w-full" :class="toshow_ss_save_popup === index && 'active'">
                                                    {{ tihtooh.swabSurgeName }}
                                                    <!-- <input type="radio" :id="'radio_assbl' + index" name="assembly" :checked="
                                                        selectedWellInfo?.bha_id == assembly.bha_id
                                                    " /> -->
                                                </div>
                                                <div v-if="tihtooh.status.toUpperCase() === 'A'" class="ss_save_list_active_dot"></div>
                                            </label>
                                            <div v-if="toshow_ss_save_popup === index" @click="toprevent_hiding" class="fixed" style="margin-left: 10rem; margin-top: -4.7rem;">
                                                <div class="sns_save_list_popup p-3">
                                                    <div v-for="(val,key) in tihtooh" :key="key">
                                                        <div class="flex pb-1" v-if="key !== 'createdAt' && key !== 'createdBy' && key !== 'wellBoreName' && key !== 'wellId'">
                                                            <label class="w-1/2" :for="key">{{ key }}</label>
                                                            <input class="w-1/2" type="text" :id="key" :value="val" disabled/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </li>
                                <li class="bg_right_">
                                    <button :class="['dropdownToggleBtn', 'text-left', 'w-full', 'rounded', 'td_btn', 'flex', darkDark !== 'white'  ? 'td_btn_dark' : 'drop_btn_light',]"
                                        v-on:click="toggle_height_handler_tooh">
                                        TOOH
                                        <span v-if="!isExpandAssemblySection"><i class="fas fa-angle-down"></i></span>
                                        <span v-else><i class="fas fa-angle-up"></i></span>
                                    </button>
                                    <ul v-if="isExpandAssemblySection" v-bind:style="{ height: isExpandAssemblySection ? '' : '0px', }" class="text-xs px-2 flex flex-col"
                                        :class="[darkDark !== 'white'  ? 'drop_backg' : '']" style="max-height: 30vh; overflow: auto;">
                                        <li v-for="(tihtooh,index) in TOOH" :key="index" @click="TOOH_Details(index)" :style="[tihtooh.status.toUpperCase() === 'A' ? {order : 1} : {order : 2}]">
                                            <label :for="'radio_sec' + index" :class="['text-left', 'w-full', 'rounded', 'td_btn', 'flex',]">
                                                <div class="flex w-full" :class="toshow_ss_save_tooh_popup === index && 'active'">
                                                    {{ tihtooh.swabSurgeName }}
                                                </div>
                                                <div v-if="tihtooh.status.toUpperCase() === 'A'" class="ss_save_list_active_dot"></div>
                                            </label>
                                            <div v-if="toshow_ss_save_tooh_popup === index" @click="toprevent_hiding" class="fixed" style="margin-left: 10rem; margin-top: -4.7rem;">
                                                <div class="sns_save_list_popup p-3">
                                                    <div v-for="(val,key) in tihtooh" :key="key">
                                                        <div class="flex pb-1" v-if="key !== 'createdAt' && key !== 'createdBy' && key !== 'wellBoreName' && key !== 'wellId'">
                                                            <label class="w-1/2" :for="key">{{ key }}</label>
                                                            <input class="w-1/2" type="text" :id="key" :value="val" disabled/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                        <div class="py-0 flex  mneminics_mt">
                            <h6 class="px-1 py-0 drop_btn font-thin text-center text-white sidebar_heading"
                                style="width: 100%; display: flex">
                                SWAB SURGE TRIGGER
                            </h6>
                        </div>
                        <div v-if="showInsideData == false" class="left_lists_">
                            <ul v-if="true" class="left_mnemo h-full">
                                <li class="bg_right_">
                                    <button :class="['dropdownToggleBtn', 'text-left', 'w-full', 'rounded', 'td_btn', 'flex', darkDark !== 'white'  ? 'td_btn_dark' : 'drop_btn_light',]"
                                        v-on:click="toggle_height_handler_tiht">
                                        TIH (Completed / Running)
                                        <span v-if="!isExpandTIHSelection"><i class="fas fa-angle-down"></i></span>
                                        <span v-else><i class="fas fa-angle-up"></i></span>
                                    </button>
                                    <ul v-if="isExpandTIHSelection" v-bind:style="{ height: isExpandTIHSelection ? '' : '0px', }" class="text-xs px-2"
                                        :class="[darkDark !== 'white'  ? 'drop_backg' : '']" style="max-height: 30vh; overflow: auto;">
                                        <li v-for="(tihtooh,index) in TIHT" :key="index" @click="trigger_list_click(index,tihtooh.swabSurgeName,tihtooh.status)">
                                            <label v-if="!tihtooh.swabSurgeName.includes('TOOH')" :for="'radio_sec' + index" :class="['text-left', 'w-full', 'rounded', 'td_btn', 'flex',]">
                                                <div class="flex w-full justify-between" :class="active_trigger_list === index && 'active'">
                                                    {{ tihtooh.swabSurgeName }}
                                                    <!-- <span> - </span>
                                                    <span>{{ show_status(tihtooh.status) }}</span> -->
                                                    <!-- <input type="radio" :id="'radio_assbl' + index" name="assembly" :checked="
                                                        selectedWellInfo?.bha_id == assembly.bha_id
                                                    " /> -->
                                                </div>
                                                <div v-if="tihtooh.status == '0'" class="ss_save_list_active_dot"></div>
                                            </label>
                                        </li>
                                    </ul>
                                </li>
                                <li class="bg_right_">
                                    <button :class="['dropdownToggleBtn', 'text-left', 'w-full', 'rounded', 'td_btn', 'flex', darkDark !== 'white'  ? 'td_btn_dark' : 'drop_btn_light',]"
                                        v-on:click="toggle_height_handler_tooht">
                                        TOOH (Completed / Running)
                                        <span v-if="!isExpandTOOHSelection"><i class="fas fa-angle-down"></i></span>
                                        <span v-else><i class="fas fa-angle-up"></i></span>
                                    </button>
                                    <ul v-if="isExpandTOOHSelection" v-bind:style="{ height: isExpandTOOHSelection ? '' : '0px', }" class="text-xs px-2"
                                        :class="[darkDark !== 'white'  ? 'drop_backg' : '']" style="max-height: 30vh; overflow: auto;">
                                        <li v-for="(tihtooh,index) in TIHT" :key="index" @click="trigger_list_click(index,tihtooh.swabSurgeName,tihtooh.status)">
                                            <label v-if="tihtooh.swabSurgeName.includes('TOOH')" :for="'radio_sec' + index" :class="['text-left', 'w-full', 'rounded', 'td_btn', 'flex',]">
                                                <div class="flex w-full justify-between" :class="active_trigger_list === index && 'active'">
                                                    {{ tihtooh.swabSurgeName }}
                                                    <!-- <span> - </span>
                                                    <span>{{ show_status(tihtooh.status) }}</span> -->
                                                    <!-- <input type="radio" :id="'radio_assbl' + index" name="assembly" :checked="
                                                        selectedWellInfo?.bha_id == assembly.bha_id
                                                    " /> -->
                                                </div>
                                                <div v-if="tihtooh.status == '0'" class="ss_save_list_active_dot"></div>
                                            </label>
                                        </li>
                                    </ul>
                                </li>    
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </div>
</template>

<script>
import axios from "axios";
// import { ChevronRightIcon } from '@heroicons/vue/solid';
// import { ChevronLeftIcon } from '@heroicons/vue/solid';
import configjs from "../../config"
let flag = true;
export default {
    data() {
        return {
            collapseShow: 'hidden',
            showInsideData: false,
            isExpandWellSection: true,
            isExpandAssemblySection: false,
            isExpandTIHSelection: false,
            isExpandTOOHSelection: false,
            TIH:[],
            TOOH:[],
            TIHT:[],
            sideBarForProp: this.sideBarForProp,
            wellId: this.wellId,
            wellboreId: this.wellboreId,
            toshow_ss_save_popup: null,
            toshow_ss_save_tooh_popup: null,
            active_trigger_list: null,
            toUpdate_ss_save_list: this.toUpdate_ss_save_list
        };
    },
    emits: ['change_toUpdate_ss_save_list','ss_trigger_list_click'],
    props: {
        sideBarForProp: String,
        wellId: String,
        wellboreId: String,
        zoomOnOff: Function,
        selectedWellInfo: Object,
        selectedSavedRun: Object,
        isZoomFeature: Boolean,
        toUpdate_ss_save_list: Boolean,
        swab_surge_save_data: Object,
        swab_surge_trigger_data: Object,
    },
    computed: {
        darkDark() {
            return this.$store.state.rect.darkmode;
        },
        TIH: function() {
            return this.TIH.filter(function(item) {
                // return item.substring(0,3);
                // console.log(item.swabSurgeName.substring(0,3) , " item")
                if(item.swabSurgeName.substring(0,3).toUpperCase() == "TIH"){
                    return item.swabSurgeName;
                }
            })
        },
        TOOH: function() {
            return this.TOOH.filter(function(item) {
                // return item.substring(0,3);
                // console.log(item.swabSurgeName.substring(0,3) , " item")
                if(item.swabSurgeName.substring(0,4).toUpperCase() == "TOOH"){
                    return item.swabSurgeName;
                }
            })
        },
        // TIHT: function() {
        //     return this.TIHT.filter(function(item) {
        //         // return item.substring(0,3);
        //         console.log(item.swabSurgeName.substring(0,3) , " item")
        //         if(item.swabSurgeName.substring(0,3).toUpperCase() == "TIH"){
        //             return item.swabSurgeName;
        //         }
        //     })
        // },
    },
    async mounted() {
        this.$store.subscribe(async (mutation, state) => {
            switch (mutation.type) {
                case 'disp/setDisplay':
                    this.collapseShow = 'hidden'
                    this.showInsideData = false
                    this.isExpandWellSection = true
                    this.isExpandAssemblySection = false
                    this.isExpandTIHSelection = false
                    this.isExpandTOOHSelection = false
                break;
            }
        })
        this.toget_left_sidebar_data()
        // let results = axios.post(configjs.HOST_URL.DATASERVICES_URL + "swabsurgeinput/search", {
        //     // wellId: this.wellId,
        //     // wellBoreName: this.wellboreId,
        //         wellId: "w1",
        //         wellBoreName: "wb1",
        //     }).then((response) => {
        //         this.TIH = response.data;
        //         this.TOOH = response.data;
        //     })
        //     let result = axios.post(configjs.HOST_URL.DATASERVICES_URL + "swabsurge/search", {
        //     // wellId: this.wellId,
        //     // wellBoreName: this.wellboreId,
        //         wellId: "w1",
        //         wellBoreName: "wb1",
        //     }).then((resp) => {
        //         this.TIHT = resp.data;
        //         // this.TOOHT = resp.data;
        //         console.log("this.TIHT ", this.TIHT)
        //     })
    },
    methods: {

        dark() {
            document.querySelector('nav').classList.add('dark-mode');
            document.querySelector('div').classList.add('dark-mode');
            document.querySelector('button').classList.add('dark-mode');
            document.querySelector('a').classList.add('text-red-600');
            this.darkMode = true;
            this.$emit('dark');
        },

        light() {
            document.querySelector('nav').classList.remove('dark-mode');
            document.querySelector('div').classList.remove('dark-mode');
            document.querySelector('button').classList.remove('dark-mode');
            document.querySelector('a').classList.remove('text-red-600');
            this.darkMode = false;
            this.$emit('light');
        },
        toggle_height_handler_tih() {
            this.isExpandWellSection = !this.isExpandWellSection;

        },
        toggle_height_handler_tiht() {
            this.isExpandTIHSelection = !this.isExpandTIHSelection;

        },
        toggle_height_handler_tooht() {
            this.isExpandTOOHSelection = !this.isExpandTOOHSelection;

        },
        toggle_height_handler_tooh() {
            this.isExpandAssemblySection = !this.isExpandAssemblySection;
        },
        async zoom_toggle() {
            this.zoom_checked = !this.zoom_checked;
            this.zoomOnOff();
        },
        rtCheckbox(e) {
            this.rtToggle(
                this.toggleValue,
                this.planid,
                this.bha_id,
                this.sourceid,
                e.target.checked
            );
        },
        TIH_Details(index){
            this.toshow_ss_save_tooh_popup = null
            if(this.toshow_ss_save_popup === index){
                this.toshow_ss_save_popup = null
            }
            else{
                this.toshow_ss_save_popup = index
            }
        },
        TOOH_Details(index){
            this.toshow_ss_save_popup = null
            if(this.toshow_ss_save_tooh_popup === index){
                this.toshow_ss_save_tooh_popup = null
            }
            else{
                this.toshow_ss_save_tooh_popup = index
            }
        },
        trigger_list_click(index,swabSurgeName,status){
            if(status == 4){
                this.active_trigger_list_click(index),
                this.$emit('ss_trigger_list_click',swabSurgeName)
            }
            else if(status == 0){
                this.active_trigger_list_click(index), 
                this.$emit('ss_trigger_list_click',swabSurgeName, false, 'running')
            }
        },
        active_trigger_list_click(index){
            this.active_trigger_list = index
        },
        toprevent_hiding(event){
            event.stopPropagation();
        },
        toget_left_sidebar_data(){
            if(this.wellId && this.wellboreId){
                this.toshow_ss_save_popup = null
                this.toshow_ss_save_tooh_popup = null
                let results = axios.post(configjs.HOST_URL.SERVER_URL + "public/swabsurgeinput/search", {
                wellId: this.wellId,
                wellBoreName: this.wellboreId,
                    // wellId: "w1",
                    // wellBoreName: "wb1",
                }).then((response) => {
                    if(response.status == 200){
                        this.TIH = response.data;
                        this.TOOH = response.data;
                    }
                })
                .then(()=>{
                    let result = axios.post(configjs.HOST_URL.SERVER_URL + "public/swabsurge/search", {
                    wellId: this.wellId,
                    wellBoreName: this.wellboreId,
                        // wellId: "w1",
                        // wellBoreName: "wb1",
                    }).then((resp) => {
                        if(resp.status == 200){
                            this.TIHT = resp.data;
                        }
                        // this.TOOHT = resp.data;
                        // console.log("this.TIHT ", this.TIHT)
                    })
                })
            }
        },
        get_ss_trigger_data(){
            if(this.wellId && this.wellboreId){
                    axios.post(configjs.HOST_URL.SERVER_URL + "public/swabsurge/search", {
                    wellId: this.wellId,
                    wellBoreName: this.wellboreId,
                        // wellId: "w1",
                        // wellBoreName: "wb1",
                    }).then((resp) => {
                        if(resp.status == 200){
                            this.TIHT = resp.data;
                        }
                        else if(resp.status == 204){
                            this.TIHT = [];
                        }
                        // this.TOOHT = resp.data;
                        // console.log("this.TIHT ", this.TIHT)
                    })
            }
        },
        show_status(status){
            if(status == 0){
                return 'Running';
            }
            else if(status == 1){
                return 'Paused';
            }
            else if(status == 2){
                return 'Paused Running';
            }
            else if(status == 3){
                return 'Stopped';
            }
            else if(status == 4){
                return 'Completed';
            }
        },
    },
    components: {
        // ChevronRightIcon,
        // ChevronLeftIcon,
    },
    watch: {
        // wellboreId(val){
        //     if(val){
        //         this.active_trigger_list = null
        //         this.toget_left_sidebar_data()
        //     }
        // },
        async toUpdate_ss_save_list(val){
            if(val){
                await this.get_ss_trigger_data()
                await this.$emit('change_toUpdate_ss_save_list');
            }
            return
        },
        swab_surge_save_data(val){
            this.toshow_ss_save_popup = null
            this.toshow_ss_save_tooh_popup = null
            this.active_trigger_list = null
            this.TIH = val;
            this.TOOH = val;
        },
        swab_surge_trigger_data(val){
            this.toshow_ss_save_popup = null
            this.toshow_ss_save_tooh_popup = null
            this.active_trigger_list = null
            this.TIHT = val;
        }
    },
}
</script>
  <!-- src="../../assets/styles/propstyle.css" -->
<style scoped  >
.close-sidebar-16rem {
    left: -16rem;
    transition: left 600ms ease-out;
}

.mySlider {
    transform: rotate(270deg);
}


.z_index_20 {
    z-index: 99999;
}

.mx_height_side {
    height: 100%;
}

.text-gray-40 {
    color: #ffffff;
}

.dropdown_hover {
    background: #29292f;
    margin: 10px;
    border-radius: 5px;
}

/* .dropdown_hover:hover {
    background-color: #414141 !important;
  } */
.dropdown_hover:hover {
    background-color: #5aba81 !important;
    color: #ffffff !important;
}

.menu_label {
    font: normal normal normal 12px Poppins;
    color: #e4e4e4;
}

.menu_label_option {
    font: normal normal normal 11px Poppins;
    text-align: left;
    color: #e4e4e460;
    padding-left: 1.5rem;
}

.menu_label_option:hover {
    color: #e4e4e4;
}

.mt_50 {
    margin-top: 30px;
}

/* .hamburger_icon {
    position: absolute;
    left: 7px;
    top: 56px;
    z-index: 60;
  } */

.form-group {
    display: block;
    margin-bottom: 0;
    margin-top: 0;
}

.form-group input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
}

.form-group label {
    position: relative;
    cursor: pointer;
    font-size: 10px;
}
.form-group input:checked+label:after {
    content: '';
    display: block;
    position: absolute;
    top: 3px;
    left: 7px;
    width: 6px;
    height: 11px;
    border: solid white;
    border-width: 0 1px 1px 0;
    transform: rotate(45deg);
}

.form-group-checkbox {
    margin-top: 0;
    margin-bottom: 0;
}

::-webkit-scrollbar {
    width: 2px !important;
    display: block !important;
}

/* Track */
::-webkit-scrollbar-track {
    background: #3d3d3d !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #5aba81 !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #5aba81 !important;
}

.search {
    padding: 10px;
    height: 10px;
    left: 5px;
    width: 90%;
    margin: 0 auto;
    display: flex;
}

.left_bg {
    background: #eeeeee;
    width: 20%;
    color: #000000 !important;
}

.drop_btn_light {
    font-size: 10px;
    padding: 2px 10px;
    font-weight: 100;
    letter-spacing: 0.5px;
    margin: 0;
}

.dark-mode {
    background: #1b1a1f;
    color: white !important;
    transition-delay: 150ms;
    width: 20%;
}
.drop_btn {
    margin: 0;
    font-size: 8px;
    padding: 2px 10px;
    font-weight: 100;
    letter-spacing: 0.5px;
}

.td_btn {
    margin: 0;
    font-size: calc(0.2vw + 0.2vh + 0.65vmin);
    padding: 4px 10px;
    margin: 6px auto;
    display: flex;
    justify-content: space-between;
}

.td_btn_dark {
    color: var(--colorBoxHeadingText);
    font-weight: 500;
    background: var(--nav1BgClr);
}

.select_opt {
    padding: 5px 10px;
    margin-bottom: 3px;
    font-size: 8px;
}

.td_toggle-switch {
    margin: 0;
    display: inline-block;
    width: 11px;
    height: 6px;
    -webkit-transform: scale(2);
    transform: scale(2);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    float: right;
    -ms-flex-item-align: end;
    align-self: end;
    -ms-flex-line-pack: end;
    align-content: end;
    align-self: end;
    align-items: center;
    align-self: center;
    margin-left: 10px;
}

.td_justify_end {
    display: flex;
    justify-content: end;
}

.left_mnemo {
    scrollbar-color: yellow blue;
    scrollbar-color: #87ceeb #ff5621;
    scrollbar-color: auto;
}

.left_lists_ {
    overflow-x: hidden;
}
.general_checks {
    display: flex;
    justify-content: space-evenly;
    margin: 15px 0;
}

#dragPosition {
    border: 1px solid gray;
}

#resizePosition {
    border: 1px solid gray;
}

input {
    outline: 0 !important;
    -webkit-appearance: none;
}

input[type='checkbox']:focus {
    outline: 0;
}

.alert_message {
    font-size: 6px;
    text-align: center;
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: center;
    height: 100%;
}

.label_heading {
    font-weight: 700;
}
.menu_btns {
    position: absolute;
    left: 32px;
    top: 50px;
    z-index: 60;
}

.left_close_btn {
    padding: 0 1px;
    display: flex;
    height: 100%;
    align-self: center;
    width: 9px;
}
.btns_modal_p {
    display: flex;
    justify-content: space-between;
}
.bg_clear {
    background-color: orange;
}

.modal_width_sidebar {
    width: 32%;
}

.left_z_index {
    z-index: 9999;
    width: 100%;
}
.tooltip__tip {
    position: relative;
}
.tooltip__tip::after {
    background-color: #000000;
    border-radius: 10px;
    display: none;
    padding: 10px 15px;
    position: absolute;
    text-align: center;
    z-index: 999;
}
.tooltip__tip::before {
    background-color: #000000;
    content: ' ';
    display: none;
    position: absolute;
    width: 7px;
    height: 7px;
    z-index: 999;
}
.tooltip__tip:hover::after {
    display: block;
    color: white;
}

.tooltip__tip:hover::before {
    display: block;
}
.tooltip__tip.top_::after {
    content: attr(data-tip);
    top: 10px;
    left: 50%;
    width: 100%;
    font-size: 7px;
    padding: 3px;
    transform: translate(-50%, calc(-100% - 10px));
}
.tooltip__tip.top_::before {
    top: 9px;
    left: 50%;
    transform: translate(-50%, calc(-100% - 5px)) rotate(45deg);
}
.parent {
    background: unset;
    width: 92%;
    height: 100%;
    margin: 0 auto;
}
.selectedBtn {
    position: relative;
}

.selectedBtn_tooltip {
    width: 100%;
}

.td_btn {
    margin: 0;
    font-size: calc(0.2vw + 0.2vh + 0.65vmin);
    padding: 4px 10px;
    display: flex;
    justify-content: space-between;
    /* color: var(--colorBoxHeadingText); */
    height: 30px;
    align-items: center;
    font-weight: 400;
    border-radius: 0;
    color: var(--textColor);
}
.switch_text_top {
    font-size: 8px;
}
.toggle-switch {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 10px;
    -webkit-transform: scale(2);
    transform: scale(2);
}
.checkbox {
    opacity: 0;
    width: 0;
    height: 0;
}
.switch-btn {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 30px;
    background-color: #414141;
    cursor: pointer;
    transition: 0.4s;
    -webkit-transition: 0.4s;
}
.switch-btn::before {
    position: absolute;
    content: '';
    height: 6px;
    width: 6px;
    left: 0px;
    bottom: 0px;
    border-radius: inherit;
    background-color: #fff;
    -webkit-box-shadow: 0 0 4px rgb(0 0 0 / 15%);
    box-shadow: 0 0 4px rgb(0 0 0 / 15%);
    -webkit-transition: 0.4s ease all,
        left 0.4s cubic-bezier(0.18, 0.89, 0.35, 1.15);
    transition: 0.4s ease all, left 0.4s cubic-bezier(0.18, 0.89, 0.35, 1.15);
}

.switch-btn:active:before {
    width: 40px;
}

.checkbox:checked+.switch-btn {
    background-color: #414141;
}
.checkbox:checked+.switch-btn::before {
    left: calc(100%);
    transform: translateX(-100%);
}
.side_pan_width {
    width: 13%;
    /* background: var(--sidebarMnList); */
    background: var(--sidebarbg);

}
.side_pan_no_width {
    width: 0 !important;
}
.list_td {
    font-size: 10px;
    font-weight: normal;
}
.tooltip__tip {
    position: relative;
}
.tooltip__tip::after {
    background-color: #000000;
    border-radius: 10px;
    display: none;
    padding: 10px 15px;
    position: absolute;
    text-align: center;
    z-index: 999;
}
.tooltip__tip::before {
    background-color: #000000;
    content: ' ';
    display: none;
    position: absolute;
    width: 7px;
    height: 7px;
    z-index: 999;
}
.tooltip__tip:hover::after {
    display: block;
}
.tooltip__tip:hover::before {
    display: block;
}
.tooltip__tip.top::after {
    content: attr(data-tip);
    top: 2px;
    left: 50%;
    width: 100%;
    font-size: 7px;
    padding: 3px;
    transform: translate(-50%, calc(-100% - 10px));
}
.tooltip__tip.top::before {
    top: 0;
    left: 50%;
    transform: translate(-50%, calc(-100% - 5px)) rotate(45deg);
}
.parent {
    background: unset;
    width: 92%;
    height: 100%;
    margin: 0 auto;
}
.selectedBtn {
    position: relative;
}
.checkbox {
    opacity: 0;
    position: absolute;
}
.td_checkbox {
    background-color: var(--toggleSliderBg);
    width: 30px;
    height: 14px;
    border-radius: 50px;
    position: relative;
    padding: 5px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 !important;
}
.checkbox:checked+.td_checkbox_zoom .ball {
    transform: translateX(13px);
}
.checkbox:checked+.td_checkbox_zoom {
    background: var(--activeTextColor);
}
.checkbox:checked+.ball {
    background-color: red;
}
.td_checkbox_zoom {
    background-color: var(--toggleSliderBg);
    width: 30px;
    height: 14px;
    border-radius: 50px;
    position: relative;
    padding: 5px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.td_checkbox_zoom .ball {
    background-color: var(--sidebarbg);
    width: 12px;
    height: 12px;
    position: absolute;
    left: 3px;
    top: 1px;
    border-radius: 50%;
    transition: transform 0.2s linear;
}
.theme_icon {
    font-size: 12px !important;
}
.td_checkbox .ball {
    background-color: var(--sidebarbg);
    width: 12px;
    height: 12px;
    position: absolute;
    left: 3px;
    top: 1px;
    border-radius: 50%;
    transition: transform 0.2s linear;
}
.checkbox:checked+.td_checkbox .ball {
    transform: translateX(13px);
}

.checkbox:checked+.td_checkbox {
    background: var(--activeTextColor);
}
.td_toggle {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 10px;
    color: var(--colorBoxHeadingText);
    font-size: 11px;
}
.active_toggle_text {
    color: var(--activeTextColor);
}
.arrow_s {
    margin: 0 3px;
}
.drop_backg {
    background: var(--sidebarbg);
}

[type='radio']:checked {
    background-image: url(../../assets/img/radio_button_selected_custom.svg) !important;
}
.sidebar_heading {
    font-weight: 400;
    /* font-size: 12px; */
    text-transform: uppercase;
    /* color: var(--headingColor); */
    padding: 0px;
    letter-spacing: normal;
    height: 32px;
    align-items: center;
    justify-content: center;
    font-size: calc(0.3vw + 0.3vh + 0.35vmin);
    color: var(--textColor);
    letter-spacing: 2px;
}

.bg_right_ {
    margin: 0px 0px 3px 0px !important;
    /* background: var(--sidebarListBgColor) !important; */
    border-radius: 0px;
}
.fa-angle-down {
    color: #727272;
}
.fa-angle-up {
    color: #727272;
}
.td_text-xs {
    font-size: calc(0.2vw + 0.2vh + 0.65vmin);
  font-weight: 600;
}
.sns_save_list_popup{
    background-color: var(--sidebarbg);
    color: var(--textColor);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    font-size: calc(0.3vw + 0.3vh + 0.35vmin);
    text-transform: uppercase;
}
.sns_save_list_popup input{
    background-color: var(--sidebarListBgColor);
    padding: 3px 5px;
}
.sns_save_list_popup::before {
    content: '';
    border-right: 10px solid var(--sidebarbg);
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    left: -10px;
    top: 22%;
    position: absolute;
}
.active{
    color: var(--activeTextColor);
}
.ss_save_list_active_dot{
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: green;
}
::-webkit-scrollbar-track {
    background: var(--nav1BgClr) !important;
}
::-webkit-scrollbar-thumb {
    background: var(--activeTextColor) !important;
}
.dropdownToggleBtn{
    background-color: var(--sidebarListBgColor);
    font-weight: 400;
}
</style>

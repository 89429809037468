   <template>
  <div>
    <canvas :ref="id" :width="totl_width" :height="totl_height" @click="canvasClick" @mousemove="handleSelectionMove" @mouseout="$emit('hide_tool_tip')"></canvas>
    <div
      :id="id + 'tooltip'"
      style="
        position: absolute;
        display: none;
        background-color: #fff;
        padding: 5px;
        border: 1px solid #ccc;
      "
    ></div>
  </div>
</template>
  <script>
import * as d3 from "d3";
export default {
  name: "CnvasLineChart",
  emits: ['show_tool_tip', 'hide_tool_tip', 'real_time_points_right_bar', ],
  props: {
    isTooltip: {
      type: Boolean,
      default: false,
    },
    isZoom: {
      type: Boolean,
      default: false,
    },
    totl_width: {
      type: Number,
      required: false,
      default: 600,
    },
    totl_height: {
      type: Number,
      required: false,
      default: 300,
    },
    config: {
      type: Object,
      required: true,
    },
    data_set: {
      type: Array,
      required: true,
    },
    min_X: {
      type: Number,
      default: 0,
    },
    min_Y: {
      type: Number,
      default: 0,
    },
    max_X: {
      type: Number,
      default: 100,
    },
    max_Y: {
      type: Number,
      default: 100,
    },
    id: {
      type: String,
      default: "mycanvasId",
    },
    diamondShapeData: {
      type: Array,
      default: [],
    },
    isInvert: {
      type: Boolean,
      default: false,
    },
    isLeftBar: {
      type: Boolean,
      default: false,
    },
    axisColor:{
      type: String,
      default: '',
    },
  },
  data() {
    return {
      minX: 0,
      minY: 0,
      maxX: 0,
      maxY: 0,
      unitsPerTickX: 200,
      unitsPerTickY: 2100,
      yAxisTitle: this.config.yTitle,
      xAxisTitle: this.config.xTitle,

      padding: 20,
      tickSize: 5,
      // axisColor: 'var(--textColor)',
      pointRadius: 1,
      font: "10px Work Sans",
      fontHeight: 8,
      zoomed_X_Axis: null,
      zoomed_Y_Axis: null,
      data_to_show_tool_tip: [],

      scaleX: () => {},
      scaleY: () => {},
    };
  },
  computed: {
    canvas() {
      return this.$refs[this.id];
    },
    context() {
      return this.canvas.getContext("2d");
    },
    rangeX() {
      return this.maxX - this.minY;
    },
    rangeY() {
      return this.maxY - this.minY;
    },
    numXTicks() {
      return 5;
    },
    numYTicks() {
      return 10;
    },
    x() {
      return this.getLongestValueWidth() + this.padding * 0;
    },
    y() {
      return this.padding * 0.5;
    },
    width() {
      // return this.canvas.width - this.x - this.padding * 0;
      return parseInt(this.totl_width) - this.x - this.padding * 0 - 10;
    },
    height() {
      // return this.canvas.height - this.y - this.padding * 2 - this.fontHeight;
      return parseInt(this.totl_height) - this.y - this.padding * 2 - this.fontHeight;
    },
    // scaleX() {
    //   return this.width / this.rangeX;
    // },
    // scaleY() {
    //   return this.height / this.rangeY;
    // },
    xScale() {
      return d3
        .scaleLinear()
        .domain([this.minX, this.maxX])
        // .range([0, this.width]);
        .range([this.x, this.x + this.width]);
    },
    yScale() {
      if (this.isInvert) {
        return d3
          .scaleLinear()
          .domain([this.minY, this.maxY])
          // .range([this.height, 0]);
          .range([this.y, this.y + this.height]);
      } else
        return d3
          .scaleLinear()
          .domain([this.maxY, this.minY])
          // .range([this.height, 0]);
          .range([this.y, this.y + this.height]);
    },
    // axisColor() {
    //   return getComputedStyle(this.canvas).getPropertyValue("--textColor");
    // },
  },
  methods: {
    y_axis_label_position() {
      try{
        let val = this.formatNumber(this.maxY);
        if (val != "" && val !== null) {
          let number = val.toString().replace('.', '');
          if (number.toString().length <= 2) {
            return 30;
          } else if (number.length == 3) {
            return 30;
          } else {
            return 20;
          }
        } else {
          return 20;
        }
      }
      catch(err){
        console.error('err')
        return 30;
      }
    },
    async canvasClick(event){
      if(this.isTooltip){
        const rect = this.canvas.getBoundingClientRect();
        const mouseX = event.clientX - rect.left;
        const mouseY = event.clientY - rect.top;
        let foundDataPoint = await this.findDataPoint( mouseX, mouseY)
        if(foundDataPoint){
          // console.log('foundDataPoint', foundDataPoint)
          this.$emit('real_time_points_right_bar', foundDataPoint)
        }
      }
    },
    async handleSelectionMove(event){
      if(this.isTooltip){
        const rect = this.canvas.getBoundingClientRect();
        const mouseX = event.clientX - rect.left;
        const mouseY = event.clientY - rect.top;
        let foundDataPoint = await this.findDataPoint( mouseX, mouseY)
        if(foundDataPoint){
          this.$emit('show_tool_tip', event, foundDataPoint)
        }
        else{
          this.$emit('hide_tool_tip')
        }
      }
    },
    findDataPoint( mouseX, mouseY) {
      // Iterate through your data points to find the one closest to the mouse coordinates

      for (const dataset of this.data_to_show_tool_tip) {
        if(dataset?.data){
          for (const dataPoint of dataset.data) {
            let xScale = this.xScale;
            let yScale = this.yScale;
            if (this.zoomed_X_Axis) xScale = this.zoomed_X_Axis.range(this.xScale.range());
            if (this.zoomed_Y_Axis) yScale = this.zoomed_Y_Axis.range(this.yScale.range()); 

            const x = xScale(dataPoint.x);
            const y = yScale(dataPoint.y);
            const distance = Math.sqrt((x - mouseX) ** 2 + (y - mouseY) ** 2);

            // Adjust the threshold based on your requirements
            if (distance < 10) {
              return dataPoint;
            }
          }
        }
      }

      return null; // No data point found
    },
    formatNumber(number) {
      try{
        const suffixes = ["", "k", "M", "B", "T"]; // Add more suffixes as needed
        let suffixIndex = 0;

        while (number >= 1000 && suffixIndex < suffixes.length - 1) {
          number /= 1000;
          suffixIndex++;
        }

        return Number(number).toFixed(1) + suffixes[suffixIndex];
      }
      catch(err){
        console.error(err)
      }
    },
    inItChat() {
      this.minX = this.min_X;
      this.minY = this.min_Y;
      this.maxX = this.max_X;
      this.maxY = this.max_Y;

      if (this.isZoom) {
        // console.log("before zoom call setup : ", this.width, this.height)
        const zoom = d3
          .zoom()
          .scaleExtent([1, 50])
          .translateExtent([
            [0, 0],
            [this.width, this.height],
          ])
          .extent([
            [0, 0],
            [this.width, this.height],
          ])
          .on("zoom", this.zoomed);

        d3.select(this.canvas).call(zoom);
      }
      else{
        d3.select(this.canvas).on(".zoom", null);
        this.zoomed_X_Axis = null;
        this.zoomed_Y_Axis = null;
      }

      this.drawXAxis();
      this.drawYAxis();
      this.data_to_show_tool_tip = [];
      if(this.zoomed_X_Axis || this.zoomed_Y_Axis){
        let new_xScale = this.zoomed_X_Axis;
        let new_yScale = this.zoomed_Y_Axis;
        let minX = new_xScale.domain()[0].toFixed(2);
        let maxX = new_xScale.domain()[1].toFixed(2);
        let minY = null
        let maxY = null
        if (this.isInvert) {
          minY = new_yScale.domain()[0].toFixed(2);
          maxY = new_yScale.domain()[1].toFixed(2);
        }
        else{
          minY = new_yScale.domain()[1].toFixed(2);
          maxY = new_yScale.domain()[0].toFixed(2);
        }
        this.data_set.forEach((d) => {
          if (d.data?.length > 0) {
            let filteredData = d.data.filter(
              (element) =>
                minX <= Number(element.x) &&
                Number(element.x) <= maxX &&
                minY <= Number(element.y) &&
                maxY >= Number(element.y)
            );
            // if(this.minX.toFixed(2) == minX && this.maxX.toFixed(2) == maxX && this.minY.toFixed(2) == minY && this.maxY.toFixed(2) == maxY &&
            //   filteredData.length && !filteredData.some((each)=> each.x == 0 && each.y == 0)  
            // ){
            //   filteredData.unshift({ x: 0, y: 0, data: {}})
            // }
            this.data_to_show_tool_tip.push({...d, data: filteredData});
            if(filteredData.length) this.drawLine(filteredData, d.color, d.width, d.lineType);
          }
        });
      }
      else{
        this.data_to_show_tool_tip = this.data_set;
        this.data_set.forEach((d) => {
          if (d.data?.length > 0){
            let filteredData = d.data.filter(
                (element) =>
                  this.minX <= Number(element.x) &&
                  Number(element.x) <= this.maxX &&
                  this.minY <= Number(element.y) &&
                  this.maxY >= Number(element.y)
              );
            if(filteredData.length) this.drawLine(filteredData, d.color, d.width, d.lineType);
          }
        });
      }
    },
    getLongestValueWidth() {
      this.context.font = this.font;
      var longestValueWidth = 45 + 25;
      // for (var n = 0; n <= this.numYTicks; n++) {
      //   var value = this.maxY - n * this.unitsPerTickY;
      //   longestValueWidth = Math.max(
      //     longestValueWidth,
      //     this.context.measureText(value).width
      //   );
      // }
      // console.log("longest value width : ", 30);
      return longestValueWidth;
    },
    drawXAxis() {
      let context = this.context;
      context.save();
      context.beginPath();
      context.moveTo(this.x, this.y + this.height);
      context.lineTo(this.x + this.width, this.y + this.height);
      context.strokeStyle = this.axisColor;
      context.lineWidth = 2;
      context.stroke();

      // let xAxisScale = d3
      //   .scaleLinear()
      //   .domain([this.minX, this.maxX])
      //   .range([this.x, this.x + this.width]);
      let xAxisScale = this.xScale
      // if (this.zoomed_X_Axis) xAxisScale = this.zoomed_X_Axis.range(xAxisScale.range())
      if (this.zoomed_X_Axis) xAxisScale = this.zoomed_X_Axis

      let xAxisTicks = xAxisScale.ticks(this.numXTicks);
      xAxisTicks.forEach((tick) => {
        const xPos = xAxisScale(tick);

        var label = tick;

        //Draw tick & label
        context.beginPath();
        context.strokeStyle = this.axisColor;
        context.moveTo(xPos, this.y + this.height);
        context.lineTo(xPos, this.y + this.height + this.tickSize);
        context.stroke();

        context.font = this.font;
        context.fillStyle = this.axisColor;
        context.textAlign = "center";
        context.textBaseline = "middle";
        this.context.save();
        // // context.translate(0, 0);
        // // context.rotate(Math.PI / 36);
        // context.fillText(tick, xPos, this.y + this.height + this.padding);
        // // context.rotate(-Math.PI / 36);
        // // // context.translate(-0, -0);

        context.translate(xPos, this.y + this.height + this.padding);
        context.rotate(-Math.PI / 4);
        context.fillText(this.formatNumber(label), 0, 0);
        context.restore();

        //add grig gridlines
        context.closePath();
        context.beginPath();
        context.moveTo(xPos, this.y);
        context.lineTo(xPos, this.y + this.height);
        // context.strokeStyle = "#ddd";
        context.strokeStyle = this.axisColor;
        context.lineWidth = 0.5;
        context.setLineDash([5, 5]);
        context.stroke();
        context.closePath();
      });
      context.rotate(-Math.PI / 2);
      let leftPosition = this.y_axis_label_position();
      context.fillText(
        this.yAxisTitle,
        -this.y - this.height / 2 + 30,
        leftPosition - 10
        // this.padding - 10
      );
      context.rotate(Math.PI / 2);
      this.context.fillText(
        this.xAxisTitle,
        this.canvas.width / 2 + (this.getLongestValueWidth() / 2),
        this.height + this.padding * 2.5
      );

      context.restore();
    },

    drawYAxis() {
      let context = this.context;
      context.save();
      context.beginPath();
      context.moveTo(this.x, this.y);
      context.lineTo(this.x, this.y + this.height);
      context.strokeStyle = this.axisColor;
      context.lineWidth = 2;
      context.stroke();

      // let yAxis = d3
      //   .scaleLinear()
      //   .domain([this.maxY, this.minY])
      //   .range([this.y, this.y + this.height]);
      let yAxis = this.yScale
      if (this.isInvert) {
        yAxis = d3
          .scaleLinear()
          .domain([this.minY, this.maxY])
          .range([this.y, this.y + this.height]);
      }

      if (this.zoomed_Y_Axis) yAxis = this.zoomed_Y_Axis;

      const tickValues = yAxis.ticks(this.numYTicks);

      tickValues.forEach((tick, i) => {
        const yPos = yAxis(tick);
        //Draw tick & label
        context.beginPath();
        context.strokeStyle = this.axisColor;
        context.moveTo(this.x - this.tickSize, yPos);
        context.lineTo(this.x, yPos);
        context.stroke();

        context.font = this.font;
        context.fillStyle = this.axisColor;
        context.textAlign = "right";
        context.textBaseline = "middle";
        context.fillText(this.formatNumber(tick), this.x - this.padding * 0.5, yPos);
        context.closePath();
        //Draw gridlines

        context.beginPath();
        context.moveTo(this.x, yPos);
        context.lineTo(this.x + this.width, yPos);
        // context.strokeStyle = "#ddd";
        context.strokeStyle = this.axisColor;
        context.lineWidth = 0.5;
        context.setLineDash([5, 5]);
        context.stroke();
        context.closePath();
      });

      context.restore();
    },

    drawLine(data, color, width, lineType = null) {
      let context = this.context;
      context.save();
      this.transformContext();
      context.lineWidth = width;
      context.strokeStyle = color;
      context.fillStyle = color;
      context.beginPath();

      // Create D3 scales for x and y
      let xScale = d3
        .scaleLinear()
        .domain([this.minX, this.maxX])
        .range([0, this.width]);
      let yScale = d3
        .scaleLinear()
        .domain([this.maxY, this.minY])
        .range([this.height, 0]);
      if (this.isInvert) {
        yScale = d3
          .scaleLinear()
          .domain([this.minY, this.maxY])
          .range([this.height, 0]);
      }

      

      this.scaleX = xScale;
      this.scaleY = yScale;
      if (this.zoomed_X_Axis) xScale = this.zoomed_X_Axis.range(xScale.range());
      if (this.zoomed_Y_Axis) yScale = this.zoomed_Y_Axis.range(yScale.range());
      // Move to the starting point of the line
      context.moveTo(xScale(data[0].x), yScale(data[0].y));

      if (lineType) this.context.setLineDash(lineType);

      // Draw the line segment by segment
      for (var n = 1; n < data.length; n++) {
        var point = data[n];
        context.lineTo(xScale(point.x), yScale(point.y));
      }

      // Stroke and close the path
      context.stroke();
      context.closePath();

      if (this.isTooltip && (this.id == 'swabsurgeRealtimeGraph' ? data.length != 1 : true)) {
        var pointColor = this.axisColor == 'black' ? "#8ecae6" : "cyan";
        var pointSize = 4;
        for (var i = 0; i < data.length; i++) {
          var point = data[i];

          context.beginPath();
          context.arc(
            xScale(point.x),
            yScale(point.y),
            pointSize,
            0,
            2 * Math.PI
          );
          context.fillStyle = pointColor;
          context.fill();
          context.closePath();
        }
      }
      context.restore();
      context.setLineDash([]);
      
    },
    drawDiamond(data) {
      let context = this.context;
      context.save();
      this.transformContext();
      const xScale = d3
        .scaleLinear()
        .domain([this.minX, this.maxX])
        .range([0, this.width]);

      const yScale = d3
        .scaleLinear()
        .domain([this.minY, this.maxY])
        .range([this.height, 0]);

        


      data.forEach((point) => {
        context.fillStyle = point.color;
        const x = xScale(point.x);
        const y = yScale(point.y);
        context.beginPath();
        context.moveTo(x, y - this.pointRadius);
        context.lineTo(x + this.pointRadius, y);
        context.lineTo(x, y + this.pointRadius);
        context.lineTo(x - this.pointRadius, y);
        context.closePath();
        context.fill();
      });

      context.restore();
    },

    transformContext() {
      var context = this.context;
      context.translate(this.x, this.y + this.height);
      context.scale(1, -1); // Flip the Y-axis scaling factor
      context.translate(0, 0);
    },
    updateScale() {
      // Update the range and number of ticks based on the new configuration
      this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
      d3.select(this.canvas).on(".zoom", null);
      this.zoomed_X_Axis = null;
      this.zoomed_Y_Axis = null;
      this.drawXAxis();
      this.drawYAxis();
    },

    handleMouseMove(event) {
      var mouseX = event.clientX - this.canvas.getBoundingClientRect().left;
      var mouseY = event.clientY - this.canvas.getBoundingClientRect().top;

      // Check if the mouse is over a data point
      for (var i = 0; i < this.data_set[0].data.length; i++) {
        var point = this.data_set[0].data[i];
        var distance = Math.sqrt(
          Math.pow(mouseX - this.scaleX(point.x), 2) +
            Math.pow(mouseY - this.scaleY(point.y), 2)
        );

        if (distance < 10) {
          // Display tooltip
          // console.log(" tooltip mouse hover : ", point);
          this.showTooltip(event, point.label);
          return;
        }
      }

      // Hide tooltip if the mouse is not over any data point
      this.hideTooltip();
    },

    // Function to display tooltip
    showTooltip(event, text) {
      var tooltip = document.getElementById(this.id + "tooltip");
      tooltip.innerHTML = text;
      tooltip.style.left = event.pageX + "px";
      tooltip.style.top = event.pageY + "px";
      tooltip.style.display = "block";
    },

    // Function to hide tooltip
    hideTooltip() {
      var tooltip = document.getElementById(this.id + "tooltip");
      tooltip.style.display = "none";
    },
    zoomed({ transform }) {
      this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
      // Update scales with zoom
      const new_xScale = transform.rescaleX(this.xScale);
      const new_yScale = transform.rescaleY(this.yScale);

      // console.log("x re scale range : ", new_xScale.domain(), new_xScale.range(), "y re scale range : ", new_yScale.domain(), new_yScale.range(), "x scale range : ", this.scaleX.domain(), "y scale range : ", this.scaleY.domain())
      // console.log(new_xScale);
      // console.log(new_yScale);

      this.zoomed_X_Axis = new_xScale;
      this.zoomed_Y_Axis = new_yScale;
      // Redraw line with new scales
      this.inItChat();
    },
  },
  mounted() {
    var devicePixelRatio = window.devicePixelRatio || 1;
    // this.canvas.width = this.canvas.width * devicePixelRatio;
    // this.canvas.height = this.canvas.height * devicePixelRatio;
    // this.context.scale(devicePixelRatio, devicePixelRatio);
    // Attach event listeners
    // this.canvas.addEventListener("mousemove", this.handleMouseMove);
    // this.canvas.addEventListener("mouseout", this.hideTooltip);

    this.inItChat();
  },
  watch: {
    axisColor(val){
      this.inItChat();
    },
    // isLeftBar(val){
    //   setTimeout(this.inItChat, 0)
    // },
    data_set: {
      handler(newValue, oldValue) {
        // console.log(" ~~~~~~ data set ~~~ ", this.id, newValue);
        this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
        this.yAxisTitle = this.config.yTitle;
        this.xAxisTitle = this.config.xTitle;
        // this.inItChat();
        setTimeout(this.inItChat, 0)
        // newValue.forEach((d) => {
        //   if (d.data?.length > 0)
        //     this.drawLine(d.data, d.color, d.width, d.lineType);
        // });
      },
      deep: true,
    },
    min_X(val) {
      this.minX = val;
      this.updateScale();
    },
    min_Y(val) {
      this.minY = val;
      this.updateScale();
    },
    max_X(val) {
      this.maxX = val;
      this.updateScale();
      // console.log("max scale X", val);
    },
    max_Y(val) {
      this.maxY = val;
      this.updateScale();
      // console.log("max scale Y", val);
    },
    diamondShapeData(val) {
      if (val.length > 0) {
        this.drawDiamond(val);
      }
    },
  },
};
</script>
  